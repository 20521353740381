import * as React from "react";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import Col from "react-bootstrap/Col";
import ReactPlayer from "react-player";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { StaticQuery, graphql } from "gatsby";
import "../styles/ipcPanels.scss";

function IPC20210418Panels() {
  const shortcodes = {
    ReactPlayer,
  };

  return (
    <StaticQuery
      query={graphql`
        query {
          allMdx(
            filter: { frontmatter: { tags: { eq: "ipc_2021_panel_april18" } } }
            sort: { order: ASC, fields: frontmatter___date }
          ) {
            nodes {
              frontmatter {
                title
                panel_time
                date
              }
              body
            }
          }
        }
      `}
      render={(data) => (
        <>
          <Row>
            <Col className="mx-3 px-0 pt-2 border-bottom border-top border-blue">
              <h3>Sunday, April 18th, 2021</h3>
            </Col>
          </Row>
          <Row className="ipc-panels">
            <Accordion defaultActiveKey="1">
              {data.allMdx.nodes.map((node, index) => (
                <Accordion.Item eventKey={index++} id={index++}>
                  <Accordion.Header>
                    <Row className="align-items-center">
                      <Col xs={12} md={2} xl={1} className="mb-3 mb-md-0 pr-0">
                        <p className="panel-time">
                          {node.frontmatter.panel_time}
                        </p>
                      </Col>
                      <Col xs={12} md={9}>
                        <h2 className="panel-title">
                          {node.frontmatter.title}
                        </h2>
                      </Col>
                    </Row>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row className="justify-content-end">
                      <Col md={10} className="py-3 border-top">
                        <MDXProvider components={shortcodes}>
                          <MDXRenderer>{node.body}</MDXRenderer>
                        </MDXProvider>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Row>
        </>
      )}
    />
  );
}

export default IPC20210418Panels;
