import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../styles/default-header.scss";

const IpcHeader = ({ headline, heroBackground, subHeadline, eventDate }) => {
  return (
    <div id="mu-hero" style={{ "--img": heroBackground }} className="ipc">
      <Container fluid="lg">
        <Row className="align-items-end">
          <Col lg={8} className="mb-md-5 ps-4 pe-5 pe-lg-0 mb-4">
            <h2 className="mb-2 pb-2 border-bottom w-40">{eventDate}</h2>
            <h1 className="mu-hero-headline mb-2">{headline}</h1>
            <h3 className="mu-hero-subheadline">{subHeadline}</h3>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default IpcHeader;
